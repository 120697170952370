@use 'styles' as theme;

@mixin _dropzoneHeight {
  height: theme.size(28);

  @include theme.mediaTabletLandscapeUp {
    height: theme.size(16);
  }
}

/* stylelint-disable selector-class-pattern */
.filepond--root {
  &.filepond--hopper {
    margin-bottom: 0;
  }

  .filepond--panel {
    @include _dropzoneHeight;
  }

  .filepond--panel-root {
    background-color: theme.$colorWhite;
  }

  .filepond--item {
    width: calc(50% - #{theme.size(1)});
  }

  .filepond--file-info {
    display: none;
  }

  .filepond--file-status {
    .filepond--file-status-main {
      @include theme.textSStrong;

      color: theme.$colorWhite;
      white-space: normal;
    }

    .filepond--file-status-sub {
      @include theme.textXS;

      color: theme.$colorWhite;
      opacity: 1;
      white-space: normal;
    }
  }

  .filepond--file {
    .filepond--file-status {
      width: 100%;
      align-items: center;
      margin: auto;
      text-align: center;
    }
  }

  .filepond--image-preview-overlay-idle {
    color: transparent;
  }

  [data-filepond-item-state*='invalid'] {
    .filepond--item-panel {
      background-color: theme.$colorRed;
    }
  }

  .filepond--file-action-button {
    padding: theme.size(0.25);
    border-radius: theme.$borderRadiusXXS;
    background-color: theme.$colorBlue400;
  }

  .filepond--drop-label {
    @include _dropzoneHeight;

    padding: theme.size(0 2);

    @include theme.mediaTabletLandscapeUp {
      justify-content: flex-start;
    }
  }
}
/* stylelint-enable selector-class-pattern */
