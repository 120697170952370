@use '@carvertical/ui/dist/lib';
@use '@carvertical/ui/styles' as theme;
@use 'filepond';

html,
body {
  height: 100%;
}

/* stylelint-disable selector-id-pattern */
#__next {
  display: flex;
  height: 100%;
  flex-direction: column;
}
/* stylelint-enable selector-id-pattern */
